// @flow

import React from "react";
import {
    DefaultLayout,
    SearchInspectionPoint as _SearchInspectionPoint,
    Layout,
    withPrivateRoute,
} from "@containers";
import { Helmet } from "react-helmet";

/**
 * Home Page
 */
const SearchInspectionPoint = (props: *) => (
    <Layout {...props}>
        <Helmet>
            <title>Inspectiepunt zoeken</title>
        </Helmet>
        <DefaultLayout title="Inspectiepunt zoeken">
            <_SearchInspectionPoint location={props.location} />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(SearchInspectionPoint);
